<template>
    <div class="w-full">
        <h1 class="text-xl" v-trans="'available-tests'">Beschikbare testen</h1>
        <hr />

        <div class="c-preloader mt-4 c-preloader--fixed" v-if="isLoading"><i></i><i></i><i></i></div>

        <div class="text-primary-normal pt-4 alert alert--danger" v-if="error" v-html="error"></div>

        <div v-show="!canTakeCourse && !this.$store.getters.isContractor">
            <p v-trans="'not-yet-invited-info'">Nog niet uitgenodigd op een test.</p>

            <hr />
        </div>

        <div v-show="!canTakeCourse && this.$store.getters.isContractor">
            <!--            <p v-trans="'not-yet-invited-info-contractor1'">-->
            <!--                Druk op <strong>Nu uitnodigen</strong> om een nieuwe test aan te vragen.-->
            <!--            </p>-->
            <!--            <p v-trans="'not-yet-invited-info-contractor2'">Je kan de test dadelijk afleggen.</p>-->
            <div class="my-4 flex items-center alert alert--info">
                <InformationOutline class="text-2xl mr-3 mb-1" />
                <p v-trans="'not-yet-invited-info-contractor3'" class="text-sm">
                    Vul je email in bij <strong>Persoonlijke info</strong> om ook een uitnodiging te ontvangen om de
                    test later af te leggen.
                </p>
            </div>

            <hr />
        </div>

        <div :key="learningTrack.id" v-for="learningTrack in learningTracks">
            <div>
                <div class="flex gap-2">
                    <IconContractor v-if="learningTrack.learningTrack[0].slug === 'contractors'" class="mb-2" />
                    <IconVisitor v-if="learningTrack.learningTrack[0].slug === 'visitors-factory'" class="mb-2" />
                    <div class="text-md mb-1" v-trans="'courseTitle-' + learningTrack.learningTrack[0].slug">
                        {{ learningTrack.learningTrack[0].title }}
                    </div>
                </div>

                <p
                    class="text-sm mb-2 whitespace-normal"
                    v-trans="'courseDescription-' + learningTrack.learningTrack[0].slug"
                >
                    {{ learningTrack.learningTrack[0].description }}
                </p>

                <div v-if="!canTakeOrContinueCourse(learningTrack)">
                    <span class="text-md" v-trans="'score'">Score</span>:
                    {{ learningTrack.scoResults.length > 0 ? learningTrack.scoResults[0].score + "%" : "N/A" }}
                </div>

                <div
                    v-if="learningTrack.status !== 'expired'"
                    :class="getClass(learningTrack)"
                    class="px-2 py-1 rounded"
                >
                    <span class="status" v-trans="learningTrack.learningTrackResultStatus[0].slug">{{
                        learningTrack.learningTrackResultStatus[0].slug
                    }}</span>
                    &nbsp;
                    <span v-html="getDate(learningTrack)"></span>
                </div>
                <div
                    v-if="learningTrack.status === 'expired'"
                    :class="getClass(learningTrack)"
                    class="px-2 py-1 rounded"
                >
                    <span class="status" v-trans="'expired'">Vervallen op</span>
                    &nbsp;
                    <span v-html="Utils.toLocaleDateString(learningTrack.expiryDate)"></span>
                </div>
            </div>

            <div v-if="!$store.getters.isViewer && canTakeOrContinueCourse(learningTrack)">
                <div class="my-2 flex items-center alert alert--info">
                    <p class="text-sm whitespace-normal" v-trans="'audio-info'">
                        Deze cursus bevat geluidsfragmenten. Ga na dat je audio-instellingen correct ingesteld staan
                        alvorens te beginnen.
                    </p>
                </div>
                <button
                    @click="selectLearningTrackResult(learningTrack)"
                    class="btn mb-2"
                    type="button"
                    v-trans="'start-course'"
                >
                    Start de cursus
                </button>
            </div>

            <hr />
        </div>

        <!--Modal-->
        <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center" v-if="showCourse">
            <div class="bg-grey-100 w-full h-full mx-auto z-50 overflow-y-auto">
                <div
                    @click="cancelCourse"
                    class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50 bg-white p-3 rounded-full"
                >
                    <svg
                        class="fill-current text-black"
                        height="18"
                        viewBox="0 0 18 18"
                        width="18"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                        ></path>
                    </svg>
                </div>

                <div class="modal-content text-left h-full v-full">
                    <iframe
                        :src="currentSCOCoursePath"
                        allowtransparency="true"
                        class="h-full"
                        frameborder="0"
                        style="position: absolute; left: 0px"
                        width="100%"
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InformationOutline from "vue-material-design-icons/InformationOutline.vue";
import { CREATE_LEARNING_TRACK_RESULT_INVITE, GET_PERSON_LEARNING_TRACKS } from "../../graphql/queries/Person";
import ButScormServerWrapper from "../../but-scormserverwrapper/ButScormServerWrapper";
import { ACTION_SET_CURRENT_CONTRACTOR } from "../../store/constants";
import { Utils } from "../../utils/utils";
import AccountHardHatIcon from "vue-material-design-icons/AccountHardHat";
import BriefcaseAccount from "vue-material-design-icons/BriefcaseAccount";

export default {
    name: "ContractorLearningPathOverview",

    components: {
        InformationOutline,
        IconContractor: AccountHardHatIcon,
        IconVisitor: BriefcaseAccount,
    },

    data() {
        return {
            Utils,
            error: "",
            scormWrapper: null,
            isLoading: true,
            selectedLearningTrackResult: null,
            showCourse: false,
            learningTracks: [],
            storeSubscription: null,
        };
    },

    mounted() {
        console.log("this.$store.state.persons.currentContractor.id", this.$store.state.persons.currentContractor.id);
        this.getPersonLearningTracks();
        this.storeSubscription = this.$store.subscribe((mutation) => {
            if (mutation.type === ACTION_SET_CURRENT_CONTRACTOR && mutation.payload !== null) {
                this.getPersonLearningTracks();
            }
        });
    },

    beforeDestroy() {
        // remove the subscription
        this.storeSubscription();
    },

    methods: {
        async getPersonLearningTracks() {
            if (this.$store.state.persons.currentContractor === null) {
                console.warn("Contractor is not logged in");
                return;
            }

            const response = await this.$apollo.query({
                query: GET_PERSON_LEARNING_TRACKS,
                variables: { id: this.$store.state.persons.currentContractor.id },
                fetchPolicy: "network-only",
            });

            this.isLoading = false;

            if (response.data && response.data.entries.length > 0) {
                this.learningTracks = [...response.data.entries[0].learningTrackResults].sort((a, b) => {
                    // Define a priority map for statuses
                    const statusPriority = {
                        "in-progress": 3,
                        "not-attempted": 2,
                        passed: 1,
                        other: 0, // Default for unknown statuses
                    };

                    // Get status priorities for each learning track
                    const statusAPriority = statusPriority[a.learningTrackResultStatus[0].slug] || 0;
                    const statusBPriority = statusPriority[b.learningTrackResultStatus[0].slug] || 0;

                    // Handle "expired" status separately
                    if (a.status === "expired") {
                        return 1; // Push expired entries to the bottom
                    } else if (b.status === "expired") {
                        return -1; // Pull expired entries to the bottom
                    }

                    // Sort by priority (higher priority comes first)
                    if (statusAPriority !== statusBPriority) {
                        return statusBPriority - statusAPriority;
                    }

                    // If priorities and status are equal, sort by update date (latest first)
                    const dateA = new Date(a.dateUpdated);
                    const dateB = new Date(b.dateUpdated);
                    return dateB - dateA; // Descending order for update date
                });

                console.log("learningTracks", this.learningTracks);

                // if there already is a selectedLearningTrackResult
                // update it to the newly gotten one
                if (this.selectedLearningTrackResult) {
                    const updatedTrack = this.learningTracks.find((track) => {
                        if (track.id === this.selectedLearningTrackResult.id) {
                            return true;
                        }
                    });

                    this.selectedLearningTrackResult = updatedTrack;
                }
            }
        },

        selectLearningTrackResult(track) {
            if (this.scormWrapper) {
                this.scormWrapper.destroy();
                this.scormWrapper = null;
            }

            this.selectedLearningTrackResult = track;
            if (this.currentSCOResult === null) {
                this.error = `ERROR: LearningTrackResult has probably not the status of the linked SCO Result(s).<br>
								  <b>Contacteer een verantwoordelijke.</b><br>Dit kan meestal worden opgelost door een nieuwe uitnodiging aan te maken.`;
            } else {
                this.selectedLearningTrackResult = track;
                let scoResultsIds = track.scoResults.map(function (scoResult) {
                    return scoResult.id;
                });

                const options = {
                    SCOResult: this.currentSCOResult,
                    SCOResults: scoResultsIds,
                    firstName: this.$store.state.persons.currentContractor.firstName,
                    lastName: this.$store.state.persons.currentContractor.lastName,
                    status: this.currentSCOResult.scoStatus[0].slug,
                    scoId: this.currentSCOResult.sco[0].id,
                    attemptsMade: this.currentSCOResult.attempts,
                    suspendData: this.currentSCOResult.suspendData,
                    rawScore: this.currentSCOResult.score ? this.currentSCOResult.score : 0,
                    lesson_location: "",
                    minScore: 0,
                    maxScore: 100,
                    apollo: this.$apollo,
                    learningTrackId: track.id,
                };
                this.scormWrapper = new ButScormServerWrapper(options, this.scoFinishedCallback, this.scoErrorCallback);
                this.showCourse = true;
            }
        },

        scoErrorCallback(errorMessage) {
            this.error = errorMessage;
        },

        getDate(learningTrackResult) {
            // console.log("Gert: learningTrackResult:", learningTrackResult);
            if (learningTrackResult.expiryDate > 0) {
                return Utils.toLocaleDateString(learningTrackResult.expiryDate);
            } else {
                if (learningTrackResult.dateUpdated) {
                    return Utils.toLocaleDateString(learningTrackResult.dateUpdated);
                } else {
                    return "";
                }
            }
        },

        getClass(learningTrack) {
            if (learningTrack.status === "expired") {
                return "lt-status status-expired";
            }

            if (learningTrack.learningTrackResultStatus && learningTrack.learningTrackResultStatus.length > 0) {
                return "lt-status status-" + learningTrack.learningTrackResultStatus[0].slug.split(" ").join("-");
            } else {
                return "";
            }
        },

        cancelCourse() {
            console.log("cancelCourse");

            this.showCourse = false;

            // refresh the learning track list
            this.selectedLearningTrackResult = null;
            this.getPersonLearningTracks();
        },

        scoFinishedCallback() {
            if (this.showCourse) {
                console.log("scoFinishedCallback");

                if (this.scormWrapper) {
                    this.scormWrapper.destroy();
                    this.scormWrapper = null;
                }

                // refresh the learning track list
                this.showCourse = false;
                this.getPersonLearningTracks().then(() => {
                    console.log("got the tracks:", this.currentSCOResult);
                    if (this.currentSCOResult === null) {
                        this.selectedLearningTrackResult = null;
                        console.log("LEARNING TRACK FINISHED");

                        if (this.$route.name === "onlineContractorLearningPathOverview") {
                            this.$router.push({ name: "onlineContractorLearningPathResult" });
                        } else {
                            this.$emit("ended");
                        }
                    } else {
                        // re-set the selectedLearningTrackResult so the next SCO is loaded
                        let selectedLearningTrackResult_ = this.selectedLearningTrackResult;
                        this.selectedLearningTrackResult = null;
                        this.selectLearningTrackResult(selectedLearningTrackResult_);
                    }
                });
            }
        },

        canTakeOrContinueCourse(course) {
            // console.log("canTakeOrContinueCourse", course);
            return (
                course.status !== "expired" &&
                course.learningTrackResultStatus[0].slug !== "passed" &&
                course.learningTrackResultStatus[0].slug !== "completed" &&
                course.learningTrackResultStatus[0].slug !== "failed"
            );
        },

        addLearningTrack() {
            this.$apollo
                .mutate({
                    mutation: CREATE_LEARNING_TRACK_RESULT_INVITE, //this also triggers the Craft plugin to attach this LT to the Person
                    variables: {
                        id: parseInt(this.$store.state.persons.currentContractor.id),
                    },
                    fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                })
                .then((data) => {
                    console.log(data);
                    this.getPersonLearningTracks();
                });
        },
    },

    computed: {
        canTakeCourse() {
            let cnt = 0;
            this.learningTracks.forEach((result) => {
                if (this.canTakeOrContinueCourse(result)) {
                    cnt++;
                }
            });
            return cnt > 0;
        },

        currentSCOCoursePath() {
            return (
                "/" +
                this.currentSCOResult.sco[0].coursePath.replace(
                    "${lang}",
                    this.$store.state.activeLanguage.toUpperCase()
                )
            );
        },

        currentSCOResult() {
            if (!this.selectedLearningTrackResult) {
                return null;
            }

            const allSCOsToComplete = this.selectedLearningTrackResult.learningTrack[0].sco;
            const SCOResults = this.selectedLearningTrackResult.scoResults;

            let currentSCOResult = null;
            allSCOsToComplete.some((sco) => {
                // iterate all SCO's that we need to finish

                const SCOResult = SCOResults.find((SCOResult) => {
                    // find a matching SCOResult
                    return SCOResult.sco[0].id === sco.id;
                });

                if (SCOResult) {
                    if (
                        SCOResult.scoStatus[0].slug === "not-attempted" ||
                        SCOResult.scoStatus[0].slug === "incomplete" ||
                        SCOResult.scoStatus[0].slug === "in-progress"
                    ) {
                        // we found a result that was not finished yet, return this
                        currentSCOResult = SCOResult;

                        // exit the some iterator
                        return true;
                    } else {
                        // passed or completed scoResult, just let the "some" iterator continue
                    }
                } else {
                    // no result found for this SCO, create one
                    currentSCOResult = {
                        scoStatus: [{ slug: "in-progress" }],
                        attempts: null,
                        suspendData: null,
                        sco: [{ id: sco.id, title: sco.title, coursePath: sco.coursePath }],
                        score: 0,
                    };

                    // exit the some iterator
                    return true;
                }
            });

            return currentSCOResult;
        },
    },
};
</script>
