<template>
    <div>
        <div class="c-preloader mt-4 c-preloader--fixed-visits" v-show="isLoading"><i></i><i></i><i></i></div>
        <div class="flex w-full">
            <div class="form-group w-1/4 flex">
                <span class="m-2" v-trans="'from'">Van</span>
                <input placeholder="DD/MM/JJJJ" class="form-control" type="text" v-model.lazy="from" />
            </div>

            <div class="form-group w-1/4 ml-4 flex">
                <span class="m-2" v-trans="'to'">Tot</span>
                <input placeholder="DD/MM/JJJJ" class="form-control" type="text" v-model.lazy="to" />
            </div>

            <div class="flex justify-end ml-auto">
                <a @click.prevent="refresh" class="flex items-center btn btn btn--primary btn--icon" href="#">
                    <div v-trans="'Refresh'">Bereken</div>

                    <RunIcon />
                </a>
            </div>

            <div class="flex justify-end ml-auto" v-show="visitLogs && visitLogs.length > 0">
                <a @click.prevent="saveExcel" class="flex items-center btn btn btn--primary btn--icon" href="#">
                    <div v-trans="'export'">Export</div>
                    <ExcelIcon />
                </a>
            </div>
        </div>

        <div class="alert alert--warning mt-8" v-show="errorMsg">{{ errorMsg }}</div>

        <div class="overflow-auto" v-show="visitLogs && visitLogs.length > 0">
            <table class="table mt-4" id="data-table">
                <thead>
                    <tr>
                        <th>Type bezoeker</th>
                        <th>Aanmelddatum</th>
                        <th>Afmelddatum</th>
                        <th>Uren aanwezig</th>
                        <th>Manueel afgemeld</th>
                        <th>Voornaam</th>
                        <th>Familienaam</th>
                        <th>Bedrijf</th>
                        <th>E-mail</th>
                        <th>Intern contact</th>
                        <th>Laden/Lossen</th>
                        <th>Vracht gewogen</th>
                        <th>Ordernummer</th>
                        <th>Nummerplaat</th>
                        <th>Telefoon</th>
                        <th>Taal</th>
                        <th>Code</th>
                        <th>Veiligheidsinstructies overtreden</th>
                        <th>Info</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :key="visit.id" v-for="visit in visitLogs">
                        <td>{{ visit.visitType[0].title }}</td>
                        <td>{{ Utils.toLocaleDateTimeString(visit.dateCreated) }}</td>
                        <td>{{ getExpiryDate(visit) }}</td>
                        <td>{{ getTimeOnSite(visit) }}</td>
                        <td>{{ getManualCheckOut(visit) }}</td>
                        <td>{{ visit.person.firstName }}</td>
                        <td>{{ visit.person.lastName }}</td>
                        <td>{{ Utils.getCompanyName(visit.person) }}</td>
                        <td>{{ visit.person.email }}</td>
                        <td>{{ visit.internalContactName }}</td>
                        <td>{{ visit.loadingOrUnloading }}</td>
                        <td>{{ getNeedsWeighting(visit) }}</td>
                        <td>{{ visit.orderNumber }}</td>
                        <td>{{ visit.person.licensePlate }}</td>
                        <td>{{ visit.person.mobilePhone }}</td>
                        <td>{{ visit.person.language }}</td>
                        <td>{{ visit.person.guid }}</td>
                        <td>{{ visit.person.safetyViolation ? "Ja" : "Nee" }}</td>
                        <td>{{ visit.person.info }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { GET_VISITS_BETWEEN3 } from "../../graphql/queries/Visit";
import moment from "moment";
import { Utils } from "../../utils/utils";
import XLSX from "xlsx";
import ExcelIcon from "vue-material-design-icons/FileExcelOutline";
import RunIcon from "vue-material-design-icons/ArrowRight";

export default {
    name: "CompanyAdminVisitLogs",
    components: { ExcelIcon, RunIcon },
    data() {
        return {
            from: moment().format("DD/MM/YYYY"),
            to: moment().format("DD/MM/YYYY"),
            searchTerm: "",
            isLoading: false,
            errorMsg: "",
            Utils,
            visitLogs: [],
        };
    },

    methods: {
        refresh() {
            this.from = this.normalizeDate(this.from);
            this.to = this.normalizeDate(this.to);
            if (this.from === "" || this.to === "") {
                this.errorMsg = "Gebruik enkel dit formaat: DD/MM/JJJJ. Vb: 02/09/2024";
                return;
            }

            this.errorMsg = "";
            this.isLoading = true;
            let variables = {
                from: [">" + this.getCraftQlReadyDateFromString(this.from)],
                to: ["<" + (this.getCraftQlReadyDateFromString(this.to) + 24 * 60 * 60)],
                forceRefresh: Date.now(),
            };
            console.log("variables:", variables);
            this.$apollo
                .query({
                    query: GET_VISITS_BETWEEN3,
                    variables,
                    // fetchPolicy: "cache-and-network"
                })
                .then((data) => {
                    if (data.data.entries) {
                        const newData = [];
                        data.data.entries.forEach((entry) => {
                            // we want these fields not deeply nested for easy sorting!
                            if (entry.person.length > 0 && this.normalizeField(entry)) {
                                newData.push(entry);
                            }
                        });
                        // console.log("Gert: newData:", newData);
                        if (data.data.entries.length === 0) {
                            this.errorMsg = "Geen bezoekers voor deze periode.";
                        } else {
                            this.errorMsg = "";
                        }
                        // console.log("Gert: newData:", newData);
                        this.visitLogs = newData;
                    } else {
                        return (this.visitLogs = []);
                    }
                })
                .catch((e) => {
                    if (e.networkError && parseInt(e.networkError.response.status) === 401) {
                        this.errorMsg = this.$trans.getTranslation("not-authorized-relogin");
                    } else {
                        this.errorMsg = JSON.stringify(e);
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        normalizeDate(date) {
            let ar = date.split("/");
            if (ar.length === 3) {
                //make sure day and month have leading zero
                ar[0] = ar[0].length === 1 ? "0" + ar[0] : ar[0];
                ar[1] = ar[1].length === 1 ? "0" + ar[1] : ar[1];
                //make sure year has 4 digits
                ar[2] = ar[2].length === 2 ? "20" + ar[2] : ar[2];
                return ar.join("/");
            }
            return "";
        },

        normalizeField(entry) {
            //console.log("entry", entry);
            if (entry.person && Array.isArray(entry.person)) {
                //do we have a visit with a disabled person? [yes]
                if (entry.person.length === 0) {
                    return null;
                }
                entry.person = { ...entry.person[0] };
            }
            return entry;
        },

        // getCompanyName(visit) {
        // 	if (visit.person.company.length > 0 ) {
        // 		return visit.person.company[0].title;
        // 	}
        // 	if (visit.person.companyName) {
        // 		return visit.person.companyName
        // 	}
        // 	return ""
        // },

        getExpiryDate(visit) {
            //still present
            if (visit.expiryDate * 1000 > Date.now()) {
                return "";
            }
            return Utils.toLocaleDateTimeString(visit.expiryDate);
        },

        getTimeOnSite(visit) {
            if (visit.expiryDate * 1000 > Date.now()) {
                return "";
            } else {
                const date1 = moment(visit.expiryDate, "X");
                const date2 = moment(visit.dateCreated, "X");
                const diff = date1.diff(date2, "hours", true);
                return diff.toFixed(1);
            }
        },

        getManualCheckOut(visit) {
            if (this.getExpiryDate(visit)) {
                return visit.manualCheckout
                    ? this.$trans.getTranslation("yes", "ja")
                    : this.$trans.getTranslation("no", "nee");
            } else {
                return "";
            }
        },

        getNeedsWeighting(visit) {
            if (visit.visitType[0].id === parseInt(this.$store.getters.getVisitTypeIdForTruckDriver)) {
                return visit.needsWeighing
                    ? this.$trans.getTranslation("yes", "Ja")
                    : this.$trans.getTranslation("no", "Nee");
            } else {
                return "";
            }
        },

        getCraftQlReadyDateFromString(s) {
            let returnDate = new Date();
            returnDate.setHours(0);
            returnDate.setMinutes(0);
            returnDate.setSeconds(0);
            returnDate.setMilliseconds(0);

            if (s.length === 10 && s.substr(2, 1) === "/" && s.substr(5, 1) === "/") {
                const day = parseInt(s.substr(0, 2));
                const month = parseInt(s.substr(3, 2));
                const year = parseInt(s.substr(6, 4));
                if (!isNaN(day) && !isNaN(month) && !isNaN(year)) returnDate = new Date(year + "/" + month + "/" + day);
            } else {
                this.errorMsg = "Gebruik enkel dit formaat: DD/MM/JJJJ. Vb: 02/09/2020";
                return "0";
            }

            return parseInt(returnDate.getTime() / 1000);
        },

        saveExcel() {
            const elTable = document.getElementById("data-table");
            const workbook = XLSX.utils.table_to_book(elTable, { sheet: "Export Visits" });
            XLSX.writeFile(workbook, "Export visits" + moment().format("DD-MM-YYYY hhumm") + ".xlsx");
        },
    },
};
</script>
