import Vue from "vue";
import { ApolloClient } from "apollo-client";
// import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import store from "../store/store";
import { ACTION_LOG_OUT, ACTION_SET_CRAFTQL_ERROR } from "../store/constants";
import { fallbackHttpConfig } from "apollo-link-http-common";
import "whatwg-fetch";
import { setContext } from "apollo-link-context";

fallbackHttpConfig.headers = {
    Accept: "application/json, */*",
    "Content-type": "application/json",
};

// const httpLink = new HttpLink({
//     uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
//     credentials: "include",
//     headers: {
//         Accept: "application/json"
//         // "Content-Type": "multipart/form-data;"
//     }
//});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            Authorization: `Bearer ${process.env.VUE_APP_GRAPHQL_TOKEN}`,
            // Authorization: `Bearer tYce2ORn6gWcxQlaGoJj_bzSCibKkX7Z`,
            // Authorization: `Bearer petK_LaCxOdpFraXfRlrkdRXh6p494ed`,
        },
    };
});

// New auth check link
const authCheckLink = new ApolloLink((operation, forward) => {
    // console.log("authCheckLink", store.state.persons.isLoggedIn, operation.operationName);
    if (!store.state.persons.isLoggedIn && !operation.operationName.startsWith("public_")) {
        // console.error("Not logged in ...");
        // return;
    }
    // If logged in, continue with the operation
    return forward(operation);
});

const uploadLink = createUploadLink({
    uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
    credentials: "include",
    headers: {
        Accept: "application/json",
        // "Content-Type": "multipart/form-data;"
    },
});

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
            console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            store.dispatch(ACTION_SET_CRAFTQL_ERROR, message);
        });
    }
    console.log("networkErr", networkError);
    if (networkError && networkError.response && parseInt(networkError.response.status) === 401) {
        console.warn(`[Network error]: ${networkError}`);
        store.dispatch(ACTION_LOG_OUT);
    }
});

// Create the apollo client
export const apolloClient = new ApolloClient({
    //link: ApolloLink.from([httpLink, errorLink, authLink, uploadLink]),
    link: authCheckLink.concat(errorLink).concat(authLink).concat(uploadLink),
    // link: ApolloLink.from([
    //     authCheckLink, // Add this new link first
    //     errorLink,
    //     authLink,
    //     uploadLink,
    // ]),
    cache: new InMemoryCache({
        // addTypename: false
    }),
    connectToDevTools: true,
    fetchOptions: { fetch },
});

// Install the Vue plugin
Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
    defaultClient: apolloClient,

    // watchLoading(isLoading, countModifier) {
    //console.log('Global loading', isLoading, countModifier)
    // }
});
